import { Entity, EntityAction, Role } from '@/types';
import { defineStore } from 'pinia';
import { useUserStore } from '~/stores/user';
import { computed, ref } from 'vue';
import { useCompanyStore } from '~/stores/company';
import { maxBy } from 'lodash';

export const usePermissionsStore = defineStore('permissionsStore', () => {
  const roles = ref<Role[]>([]);
  // const permissions = ref<Permission[] | null>(null);
  const companyStore = useCompanyStore();
  const userStore = useUserStore();

  async function hydrate() {
    // const allPermissions = [];
    // userStore.user.roles.forEach((role) => {
    //   if (role.permissions?.length) {
    //     role.permissions.forEach((perm) => {
    //       const alreadyHasEntityPermission = permissions.value.find(
    //         (item) => item.entity === perm.entity && item.action === perm.action,
    //       );
    //
    //     });
    //     allPermissions.push(...role.permissions);
    //   }
    // });
    // permissions.value = allPermissions;
    roles.value = userStore.user.roles;
  }

  const permissions = computed(() => {
    const allPermissions = [];

    roles.value.forEach((role) => {
      if (role.permissions?.length) {
        allPermissions.push(...role.permissions);
      }
    });
    return allPermissions;
  });

  function $reset() {
    roles.value = [];
  }

  function dehydrate() {
    this.$reset();
  }

  function canVisitProfile(profileId: string | string[]) {
    if (!profileId) {
      return false;
    }
    const visitingProfile = typeof profileId === 'string' ? Number.parseInt(profileId) : Number.parseInt(profileId[0]);

    if (userStore.user.id === visitingProfile) {
      return true;
    }
    const colleagues = companyStore.company.users.map((employee) => employee.id);
    if (!colleagues.length) {
      return false;
    }
    return colleagues.includes(visitingProfile);
  }
  function canReadReport(reportCode: string | string[]) {
    const code = typeof reportCode === 'string' ? reportCode : reportCode[0];
    if (!companyStore.company || !userStore?.user?.company) {
      return false;
    }
    return this.hasPermission('report', 'read', code);
  }

  function hasPermission(entity: Entity, action: EntityAction, refinement?: string | string[]) {
    let permission = null;

    const sameEntityActionFromDifferentRoles = permissions.value?.filter(
      (permission) => permission.entity === entity && permission.action === action,
    );

    //todo this needs refactoring, move to permissions compute?
    // todo merge instead of searching for the longest list
    const withDefinedFields = sameEntityActionFromDifferentRoles.filter((item) => item?.narrowing?.length);

    if (withDefinedFields.length === 0) {
      permission = sameEntityActionFromDifferentRoles[0];
    }

    if (withDefinedFields.length === 1) {
      permission = withDefinedFields[0];
    }

    if (withDefinedFields.length > 1) {
      permission = maxBy(withDefinedFields, (item) => item.narrowing.length);
    }

    if (!permission) {
      return false;
    }
    if (permission?.narrowing && !refinement) {
      return false;
    }
    if (permission?.narrowing && Array.isArray(permission?.narrowing)) {
      if (typeof refinement === 'string') {
        return permission?.narrowing?.some((item) => item.toLowerCase().includes(refinement.toLowerCase()));
      }
      if (Array.isArray(refinement)) {
        return refinement.every((item) => permission?.narrowing.includes(item.toLowerCase()));
      }
    }
    if (permission?.narrowing && typeof permission?.narrowing === 'string') {
      return permission?.narrowing?.toLowerCase() === refinement;
    }
    return Boolean(permission);
  }

  return {
    $reset,
    canReadReport,
    canVisitProfile,
    hydrate,
    dehydrate,
    hasPermission,
    permissions,
  };
});
