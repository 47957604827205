import api from '~/api/index';
import { ErrorMessageResponse } from '~/types/error';
import {
  ClientCredentials,
  ErrorResponseWithExternalApiData,
  GetAccountRecordingsParams,
  ZoomIntegrationRecordingsItem,
} from '~/types/zoom';
import { ActiveIntegrationInfo, Integration } from '~/types/integrations';

export const integrationsApi = {
  getAvailableIntegrations: async () => {
    return api.get<Integration[] | ErrorMessageResponse>('/connector/integrations');
  },

  getActiveIntegrations: async () => {
    return api.get<ActiveIntegrationInfo[] | ErrorMessageResponse>('/connector/active-integrations');
  },

  getZoomRecordings: async (params: GetAccountRecordingsParams) => {
    return api.get<ZoomIntegrationRecordingsItem[] | ErrorResponseWithExternalApiData>('/connector/recordings', {
      params,
    });
  },

  submitZoomCredentials: async (data: ClientCredentials) => {
    return api.post<boolean | ErrorResponseWithExternalApiData>('/connector/credentials', { data });
  },

  disableIntegration: async (id: number) => {
    return api.put<boolean | ErrorResponseWithExternalApiData>('/connector/disable', { params: { id } });
  },
};
