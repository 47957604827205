import api from '~/api/index';
import { User } from '~/types/users';
import { Company, EmployeePosition } from '~/types/companies';
import { ErrorMessageResponse } from '~/types/error';
import { Report } from '~/types/report';

export const companyApi = {
  getById: async (id: string | number) => {
    return api.get<Company>('/company', { params: { id } });
  },

  addEmployee: async (user: Partial<User>) => {
    return api.post<User | ErrorMessageResponse>('/users', { data: user });
  },

  getCompanyPositions: async (): Promise<EmployeePosition[]> => {
    return api.get<EmployeePosition[]>('/users/positions');
  },
  getReportsList: async () => {
    return api.get<Report[] | ErrorMessageResponse>('/report');
  },
};
