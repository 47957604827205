import { App, Component } from 'vue';
import { ru } from '~/i18n/prime-vue/ru';
import PrimeVue from 'primevue/config';
import Avatar from 'primevue/avatar';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import ScrollTop from 'primevue/scrolltop';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Tree from 'primevue/tree';
import Sidebar from 'primevue/sidebar';
import FloatLabel from 'primevue/floatlabel';
import Toast from 'primevue/toast';
import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import Tag from 'primevue/tag';
import ScrollPanel from 'primevue/scrollpanel';
import Slider from 'primevue/slider';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import Column from 'primevue/column';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import InputIcon from 'primevue/inputicon';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toolbar from 'primevue/toolbar';
import Image from 'primevue/image';
import TieredMenu from 'primevue/tieredmenu';
import Menubar from 'primevue/menubar';
import TreeSelect from 'primevue/treeselect';
import InputMask from 'primevue/inputmask';
import Checkbox from 'primevue/checkbox';

// directives
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import BadgeDirective from 'primevue/badgedirective';

import ToastService from 'primevue/toastservice';

const components = [
  Avatar,
  Button,
  Breadcrumb,
  Card,
  Carousel,
  InputText,
  FloatLabel,
  InputSwitch,
  FileUpload,
  Menu,
  OverlayPanel,
  ScrollTop,
  Splitter,
  SplitterPanel,
  Tree,
  Sidebar,
  Toast,
  Panel,
  Divider,
  Tag,
  Slider,
  ScrollPanel,
  DataTable,
  IconField,
  Column,
  MultiSelect,
  Calendar,
  Dropdown,
  ProgressBar,
  InputIcon,
  InputNumber,
  Dialog,
  TabView,
  TabPanel,
  Toolbar,
  Image,
  TieredMenu,
  Menubar,
  TreeSelect,
  InputMask,
  Checkbox,
  Listbox,
];

export function usePrimeVue(app: App) {
  // app.use(PrimeVue);
  app.use(PrimeVue, { ripple: true, locale: ru });
  app.use(ToastService);
  components.forEach((component: Component) => {
    app.component(component.name, component);
  });

  app.directive('tooltip', Tooltip);
  app.directive('ripple', Ripple);
  app.directive('badge', BadgeDirective);
  app.directive('styleclass', StyleClass);
}
