import { defineStore } from 'pinia';
import { ref } from 'vue';
import { integrationsApi } from '~/api/integrations';
import { ClientCredentials } from '~/types/zoom';
import { keyBy } from 'lodash';
import { ActiveIntegrationInfo, Integration } from '~/types/integrations';
import { isErrorResponseWithExternalApiData } from '~/types/guards/api-responses';

export const useIntegrationsStore = defineStore('integrations', () => {
  const updatingIntegration = ref<string>('');
  const loading = ref<boolean>(false);

  const error = ref<null | string>(null);
  const success = ref<null | string>(null);

  const activeIntegrations = ref<ActiveIntegrationInfo[] | null>(null);
  const activeIntegrationsNormalized = ref<{ [connectorId: string]: ActiveIntegrationInfo } | null>(null);
  const availableIntegrations = ref<Integration[] | null>(null);

  async function hydrate() {
    await this.getAvailableIntegrations();
    await this.getActiveIntegrations();
  }

  function $reset() {
    activeIntegrations.value = null;
    activeIntegrationsNormalized.value = null;
    availableIntegrations.value = null;
  }

  function dehydrate() {
    this.$reset();
  }
  async function getActiveIntegrations(): Promise<'ok' | 'error'> {
    loading.value = true;
    try {
      const res = await integrationsApi.getActiveIntegrations();
      if (isErrorResponseWithExternalApiData(res)) {
        throw new Error(res.message);
      }
      if (Array.isArray(res)) {
        activeIntegrations.value = res;
        activeIntegrationsNormalized.value = keyBy(res, 'connectorId');
        return 'ok';
      }
    } catch (error) {
      error.value = error?.message ?? 'Не удалось получить список активных интеграций';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  async function getAvailableIntegrations(): Promise<'ok' | 'error'> {
    loading.value = true;
    try {
      const res = await integrationsApi.getAvailableIntegrations();
      if (isErrorResponseWithExternalApiData(res)) {
        throw new Error(res?.message);
      }
      if (Array.isArray(res) && res?.length) {
        availableIntegrations.value = res;
        return 'ok';
      }
    } catch (error) {
      error.value = error?.message ?? 'Не удалось получить список доступных интеграций';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  async function connectZoom(data: ClientCredentials): Promise<'ok' | 'error'> {
    updatingIntegration.value = 'zoom';
    error.value = null;
    success.value = null;
    try {
      const res = await integrationsApi.submitZoomCredentials(data);
      if (isErrorResponseWithExternalApiData(res)) {
        throw new Error(res.message);
      }
      if (res === true) {
        await this.getAvailableIntegrations();
        await this.getActiveIntegrations();
        success.value = 'интеграция zoom подключена';
        return 'ok';
      }
    } catch (error) {
      error.value = error?.message ?? 'Не удалось подключить zoom';
      return 'error';
    } finally {
      updatingIntegration.value = '';
    }
  }
  async function disableIntegration(integration: Integration) {
    success.value = null;
    error.value = null;
    const activeIntegrationId = activeIntegrationsNormalized.value[integration.id].id;
    if (!activeIntegrationId) {
      return;
    }
    updatingIntegration.value = integration.name.toLowerCase();
    try {
      const res = await integrationsApi.disableIntegration(activeIntegrationId);
      if (res === true) {
        await this.getAvailableIntegrations();
        await this.getActiveIntegrations();
        success.value = 'интеграция zoom отключена';
        return 'ok';
      }
      if (isErrorResponseWithExternalApiData(res)) {
        throw new Error(res.message);
      }
    } catch (err) {
      error.value = err?.message ?? 'Ошибка отключения zoom';
    } finally {
      updatingIntegration.value = '';
    }
  }

  return {
    $reset,
    hydrate,
    dehydrate,
    disableIntegration,
    connectZoom,
    activeIntegrations,
    availableIntegrations,
    activeIntegrationsNormalized,
    getAvailableIntegrations,
    getActiveIntegrations,
    success,
    loading,
    updatingIntegration,
    error,
  };
});
