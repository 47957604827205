import { hydrate } from '@/hydrate';
import NProgress from '@/utils/progress';
import { storageLocal } from '@pureadmin/utils';
import { createRouter, NavigationGuardNext, Router } from 'vue-router';
import { getHistoryMode } from './utils';

import { usePermissionsStore } from '~/stores/permissions';
import { useUserStore } from '~/stores/user';
import commonRoutes from './common';

export const router: Router = createRouter({
  history: getHistoryMode(import.meta.env.VITE_ROUTER_HISTORY ?? 'h5'),
  routes: commonRoutes,
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        if (from.meta.saveSrollTop) {
          const top: number = document.documentElement.scrollTop || document.body.scrollTop;
          resolve({ left: 0, top });
        }
      }
    });
  },
});

const whiteList = [];

router.beforeEach(async (to: ToRouteType, _from) => {
  const userInfo = storageLocal().getItem<AuthenticationData>('user-info');
  NProgress.start();

  if (userInfo) {
    await hydrate();
    const userStore = useUserStore();
    const permissionsStore = usePermissionsStore();
    if (!userStore.user) {
      return '/login';
    }

    if (to.path.startsWith('/login')) {
      return '/upload';
    }

    if (whiteList.indexOf(to.path) !== -1) {
      return true;
    }

    if (to.path === `/profile/${userStore.user?.id}`) {
      return true;
    }

    if (!userStore.user?.company && to.path !== '/no-company') {
      return '/no-company';
    }

    if (to.path.startsWith('/profile') && !permissionsStore.canVisitProfile(to.params.id)) {
      return '/';
    }

    if (to.path.startsWith('/report') && !permissionsStore.canReadReport(to.params.type)) {
      return '/';
    }

    return true;
  }
  if (!userInfo && !to.path.startsWith('/login')) {
    return '/login';
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
