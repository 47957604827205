import { Prompt } from '~/types/companies';
import { ErrorMessageResponse, ErrorResponseWithStatusCode } from '~/types/error';
import {
  DataForDuplicationCheck,
  FileDuplicate,
  FilePresignedUrl,
  FilesHistoryItem,
  FileUploadData,
  ProcessingFileStatus,
  ProcessingJobData,
} from '~/types/uploads';
import api from './';

export const filesApi = {
  saveFileInfo: async (data: FileUploadData[]): Promise<number[] | ErrorResponseWithStatusCode> => {
    return api.post('/files', { data });
  },

  uploadToS3: async (file: File, presignedUrl: string) => {
    return fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    });
  },

  getS3PresignedUrls: async (fileNames: string[]): Promise<FilePresignedUrl[]> => {
    return api.post('/files/get-presigned-urls', {
      data: fileNames,
      timeout: 30000,
    });
  },

  markArchived: async (ids: number[]) => {
    return api.post('/files/archive', { data: { fileIds: ids } });
  },

  startProcessing: async (
    data: ProcessingJobData,
  ): Promise<ErrorMessageResponse | ProcessingFileStatus[] | ErrorResponseWithStatusCode> => {
    return api.post('/files/processing/start', { data });
  },

  getProcessingStatus: async (files: number[]): Promise<ErrorMessageResponse | ProcessingFileStatus[]> => {
    return api.post('/files/processing/status', { data: { files } });
  },

  getFilesList: async (states: string[]): Promise<ErrorMessageResponse | FilesHistoryItem[]> => {
    const queryParams = states.map((state) => `states=${state}`).join('&');
    return api.get(`/files/list?${queryParams}`);
  },

  checkIfExists: async (
    filesMeta: DataForDuplicationCheck[],
  ): Promise<ErrorMessageResponse | FileDuplicate[][] | [null]> => {
    return api.post('/files/check-duplicate', { data: filesMeta });
  },

  //todo move to company api?
  getPrompts: async (id: string | number): Promise<Prompt[]> => {
    return api.get<Prompt[]>('/prompt', { params: { companyId: id } });
  },
};
