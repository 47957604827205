<template>
  <router-view />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, onMounted, onUnmounted, toRefs, watch } from 'vue';
import { useAppStore } from '@/stores/app';
import { startIdleTracking, stopIdleTracking } from './idle';
import { usePrimeVue } from 'primevue/config';
import { useFavicon, usePreferredDark } from '@vueuse/core';

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const appStore = useAppStore();
    const PrimeVue = usePrimeVue();

    const prefersDark = usePreferredDark();
    const favicon = computed(() => (prefersDark.value ? 'favicon-light.ico' : 'favicon-dark.ico'));

    useFavicon(favicon, {
      baseUrl: '/',
      rel: 'icon',
    });

    const { hydrating } = toRefs(appStore);

    onMounted(() => {
      startIdleTracking();
    });
    onUnmounted(() => stopIdleTracking());

    watch(
      () => prefersDark.value,
      () => {
        appStore.toggleDarkMode(prefersDark.value);
      },
    );

    watch(
      () => appStore.darkMode,
      (darkMode) => {
        document.body.classList.remove('body--dark');
        document.body.classList.remove('body--light');
        document.body.classList.remove('auto');

        if (darkMode) {
          PrimeVue.changeTheme('lara-light-blue', 'lara-dark-blue', 'prime-theme', () => {});
          document.body.classList.add('body--dark');
        } else {
          PrimeVue.changeTheme('lara-dark-blue', 'lara-light-blue', 'prime-theme', () => {});

          document.body.classList.add('body--light');
        }
      },
      { immediate: true },
    );

    const error = computed(() => appStore.error);

    document.title = 'IQC';

    return { t, hydrating, error };
  },
});
</script>

<style lang="scss" scoped>
#neurohub {
  height: 100%;
}
</style>
