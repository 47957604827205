const { VITE_API_URL } = import.meta.env;
import { formatToken, getToken, refreshToken } from '@/auth';
import NProgress from '@/utils/progress';
import Axios, { AxiosInstance, AxiosRequestConfig, CustomParamsSerializer } from 'axios';
import { stringify } from 'qs';
import { HttpError, HttpRequestConfig, HttpResponse, RequestMethods } from './http';

const defaultConfig: AxiosRequestConfig = {
  baseURL: VITE_API_URL ?? 'http://localhost:3000',
  timeout: 10000,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer,
  },
};

export class HttpClient {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  private static requests = [];

  private static isRefreshing = false;

  private static initConfig: HttpRequestConfig = {};

  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  private static retryOriginalRequest(config: HttpRequestConfig) {
    return new Promise((resolve) => {
      HttpClient.requests.push((token: string) => {
        config.headers['Authorization'] = formatToken(token);
        resolve(config);
      });
    });
  }

  private httpInterceptorsRequest(): void {
    HttpClient.axiosInstance.interceptors.request.use(
      async (config: HttpRequestConfig): Promise<any> => {
        NProgress.start();

        if (typeof config.beforeRequestCallback === 'function') {
          config.beforeRequestCallback(config);
          return config;
        }

        if (HttpClient.initConfig.beforeRequestCallback) {
          HttpClient.initConfig.beforeRequestCallback(config);
          return config;
        }

        const whiteList = ['/auth/verify-code', '/auth/login', '/auth/refresh-token'];
        return whiteList.find((url) => url === config.url)
          ? config
          : new Promise((resolve) => {
              const data = getToken();

              if (data) {
                const now = new Date().getTime();
                const expired = data.expires - now <= 0;

                if (expired) {
                  if (!HttpClient.isRefreshing) {
                    HttpClient.isRefreshing = true;
                    // token
                    refreshToken(data.refreshToken)
                      .then((res) => {
                        const token = res.token;
                        config.headers['Authorization'] = formatToken(token);
                        HttpClient.requests.forEach((cb) => cb(token));
                        HttpClient.requests = [];
                      })
                      .finally(() => {
                        HttpClient.isRefreshing = false;
                      });
                  }

                  resolve(HttpClient.retryOriginalRequest(config));
                } else {
                  config.headers['Authorization'] = formatToken(data.token);
                  resolve(config);
                }
              } else {
                resolve(config);
              }
            });
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  private httpInterceptorsResponse(): void {
    const instance = HttpClient.axiosInstance;

    instance.interceptors.response.use(
      (response: HttpResponse) => {
        const $config = response.config;
        NProgress.done();

        if (typeof $config.beforeResponseCallback === 'function') {
          $config.beforeResponseCallback(response);
          return response.data;
        }

        if (HttpClient.initConfig.beforeResponseCallback) {
          HttpClient.initConfig.beforeResponseCallback(response);
          return response.data;
        }

        return response.data;
      },
      (error: HttpError) => {
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        NProgress.done();
        return Promise.reject($error);
      },
    );
  }

  public request<T>(
    method: RequestMethods,
    url: string,
    params?: AxiosRequestConfig,
    axiosConfig?: HttpRequestConfig,
  ): Promise<T> {
    const config = {
      method,
      url,
      ...params,
      ...axiosConfig,
    } as HttpRequestConfig;

    return new Promise((resolve, reject) => {
      HttpClient.axiosInstance
        .request(config)
        .then((response: undefined) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public get<T>(url: string, params?: AxiosRequestConfig, config?: HttpRequestConfig): Promise<T> {
    return this.request<T>('get', url, params, config);
  }

  public post<T>(url: string, params?: AxiosRequestConfig, config?: HttpRequestConfig): Promise<T> {
    return this.request<T>('post', url, params, config);
  }

  public put<T>(url: string, params?: AxiosRequestConfig, config?: HttpRequestConfig): Promise<T> {
    return this.request<T>('put', url, params, config);
  }

  public patch<T>(url: string, params?: AxiosRequestConfig, config?: HttpRequestConfig): Promise<T> {
    return this.request<T>('patch', url, params, config);
  }
}

export default new HttpClient();
