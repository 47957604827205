import { useAppStore } from '@/stores/app';
import { usePermissionsStore } from '@/stores/permissions';
import { useUserStore } from '@/stores/user';
import { useIntegrationsStore } from '~/stores/integrations';
import { useCompanyStore } from '~/stores/company';

type GenericStore = {
  $id: string;
  hydrate?: () => Promise<void>;
  dehydrate?: () => Promise<void>;

  [key: string]: any;
};

export function useStores(
  stores = [useUserStore, usePermissionsStore, useCompanyStore, useIntegrationsStore],
): GenericStore[] {
  return stores.map((useStore) => useStore()) as GenericStore[];
}

export async function hydrate() {
  const stores = useStores();

  const appStore = useAppStore();
  const userStore = useUserStore();
  const companyStore = useCompanyStore();
  const permissionsStore = usePermissionsStore();
  const integrationsStore = useIntegrationsStore();

  if (appStore.hydrated) return;
  if (appStore.hydrating) return;

  appStore.hydrating = true;

  try {
    /**
     * @NOTE
     * Multiple stores rely on the userStore to be set, so they can fetch user specific data. The
     * following makes sure that the user store is always fetched first, before we hydrate anything
     * else.
     */
    const res = await userStore.hydrate();
    if (res !== 'ok') {
      throw new Error('Ошибка аутентификации');
    }

    if (userStore.user?.roles) {
      await companyStore.hydrate();
      await integrationsStore.hydrate();
      await permissionsStore.hydrate();
    }

    appStore.hydrated = true;
    return 'ok';
  } catch (error: any) {
    appStore.error = error;
    return 'error';
  } finally {
    appStore.hydrating = false;
  }
}

export async function dehydrate(stores = useStores()): Promise<void> {
  const appStore = useAppStore();

  if (appStore.hydrated === false) return;

  for (const store of stores) {
    await store.dehydrate?.();
  }

  appStore.hydrated = false;
}
