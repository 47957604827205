import { buildHierarchyTree } from '@/utils/tree';
import { cloneDeep, intersection, isAllEmpty, isIncludeAllChildren, isString, storageSession } from '@pureadmin/utils';
import { isProxy, toRaw } from 'vue';
import { RouteComponent, RouteRecordRaw, RouterHistory, createWebHashHistory, createWebHistory } from 'vue-router';
import { router } from './index';

const IFrame = () => import('@/layouts/frame-view.vue');

// https://cn.vitejs.dev/guide/features.html#glob-import
const modulesRoutes = import.meta.glob('/src/pages/**/*.{vue,tsx}');

function handRank(routeInfo: any) {
  const { name, path, parentId, meta } = routeInfo;
  // eslint-disable-next-line no-nested-ternary
  return isAllEmpty(parentId)
    ? isAllEmpty(meta?.rank) || (meta?.rank === 0 && name !== 'Home' && path !== '/')
      ? true
      : false
    : false;
}

function ascending(arr: any[]) {
  arr.forEach((v, index) => {
    if (handRank(v)) v.meta.rank = index + 2;
  });

  return arr.sort((a: { meta: { rank: number } }, b: { meta: { rank: number } }) => {
    return a?.meta.rank - b?.meta.rank;
  });
}

function filterTree(data: RouteComponent[]) {
  const newTree = cloneDeep(data).filter((v: { meta: { showLink: boolean } }) => v.meta?.showLink !== false);

  newTree.forEach((v: { children }) => v.children && (v.children = filterTree(v.children)));

  return newTree;
}

function filterChildrenTree(data: RouteComponent[]) {
  const newTree = cloneDeep(data).filter((v: any) => v?.children?.length !== 0);

  newTree.forEach((v: { children }) => v.children && (v.children = filterTree(v.children)));

  return newTree;
}

function isOneOfArray(a: Array<string>, b: Array<string>) {
  // eslint-disable-next-line no-nested-ternary
  return Array.isArray(a) && Array.isArray(b) ? (intersection(a, b).length > 0 ? true : false) : true;
}

function filterNoPermissionTree(data: RouteComponent[]) {
  const currentRoles = storageSession().getItem<Record<string, any>>('user-info')?.roles ?? [];
  const newTree = cloneDeep(data).filter((v: any) => isOneOfArray(v.meta?.roles, currentRoles));
  newTree.forEach((v: any) => v.children && (v.children = filterNoPermissionTree(v.children)));
  return filterChildrenTree(newTree);
}

function getParentPaths(value: string, routes: RouteRecordRaw[], key = 'path') {
  function dfs(routes: RouteRecordRaw[], value: string, parents: string[]) {
    for (let i = 0; i < routes.length; i++) {
      const item = routes[i];
      if (item[key] === value) return parents;
      if (!item.children || !item.children.length) continue;
      parents.push(item.path);

      if (dfs(item.children, value, parents).length) return parents;
      parents.pop();
    }

    return [];
  }

  return dfs(routes, value, []);
}

function findRouteByPath(path: string, routes: RouteRecordRaw[]) {
  let res = routes.find((item: { path: string }) => item.path == path);

  if (res) {
    return isProxy(res) ? toRaw(res) : res;
  } else {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].children instanceof Array && routes[i].children.length > 0) {
        res = findRouteByPath(path, routes[i].children);

        if (res) {
          return isProxy(res) ? toRaw(res) : res;
        }
      }
    }

    return null;
  }
}

function addPathMatch() {
  if (!router.hasRoute('pathMatch')) {
    router.addRoute({
      path: '/:pathMatch(.*)',
      name: 'pathMatch',
      redirect: '/error/404',
    });
  }
}

function formatFlatteningRoutes(routesList: RouteRecordRaw[]) {
  if (routesList.length === 0) return routesList;
  let hierarchyList = buildHierarchyTree(routesList);

  for (let i = 0; i < hierarchyList.length; i++) {
    if (hierarchyList[i].children) {
      hierarchyList = hierarchyList.slice(0, i + 1).concat(hierarchyList[i].children, hierarchyList.slice(i + 1));
    }
  }

  return hierarchyList;
}

function formatTwoStageRoutes(routesList: RouteRecordRaw[]) {
  if (routesList.length === 0) return routesList;
  const newRoutesList: RouteRecordRaw[] = [];

  routesList.forEach((v: RouteRecordRaw) => {
    if (v.path === '/') {
      newRoutesList.push({
        component: v.component,
        name: v.name,
        path: v.path,
        redirect: v.redirect,
        meta: v.meta,
        children: [],
      });
    } else {
      newRoutesList[0]?.children.push({ ...v });
    }
  });

  return newRoutesList;
}

function getHistoryMode(routerHistory?: string): RouterHistory {
  const historyMode = (routerHistory ?? 'h5').split(',');
  const leftMode = historyMode[0];
  const rightMode = historyMode[1];

  // no param
  if (historyMode.length === 1) {
    if (leftMode === 'hash') {
      return createWebHashHistory('');
    } else if (leftMode === 'h5') {
      return createWebHistory('');
    }
  } //has param
  else if (historyMode.length === 2) {
    if (leftMode === 'hash') {
      return createWebHashHistory(rightMode);
    } else if (leftMode === 'h5') {
      return createWebHistory(rightMode);
    }
  }
}

function getAuths(): Array<string> {
  return router.currentRoute.value.meta.auths as Array<string>;
}

function hasAuth(value: string | Array<string>): boolean {
  if (!value) return false;
  const metaAuths = getAuths();
  if (!metaAuths) return false;

  const isAuths = isString(value) ? metaAuths.includes(value) : isIncludeAllChildren(value, metaAuths);

  return isAuths ? true : false;
}

export {
  addPathMatch,
  ascending,
  filterNoPermissionTree,
  filterTree,
  findRouteByPath,
  formatFlatteningRoutes,
  formatTwoStageRoutes,
  getAuths,
  getHistoryMode,
  getParentPaths,
  hasAuth,
  isOneOfArray,
};
