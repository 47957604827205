import { RequestError } from '@/api';
import { createI18n } from 'vue-i18n';
import { isObject } from '@pureadmin/utils';
import availableLanguages from './settings/available-languages.json';
import datetimeFormats from './settings/date-formats.json';
import numberFormats from './settings/number-formats.json';

function getLocale(prefix = 'ru') {
  const locales = Object.entries(import.meta.glob(['./locales/*.y(a)?ml'], { eager: true })).map(
    ([key, value]: any) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)[1];
      return [matched, value.default];
    },
  );

  return Object.fromEntries(locales)[prefix];
}

export const localesConfigs = {
  ru: {
    ...getLocale('ru'),
  },
  en: {
    ...getLocale('en'),
  },
};
export const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'en',
  messages: localesConfigs,
  silentTranslationWarn: true,
  // datetimeFormats,
  // numberFormats,
});

export type Language = keyof typeof availableLanguages;

export const loadedLanguages: Language[] = ['ru'];

function getObjectKeys(obj) {
  const stack = [];
  const keys = [];

  stack.push({ obj, key: '' });

  while (stack.length > 0) {
    const { obj, key } = stack.pop();

    for (const k in obj) {
      const newKey = key ? `${key}.${k}` : k;

      if (obj[k] && isObject(obj[k])) {
        stack.push({ obj: obj[k], key: newKey });
      } else {
        keys.push(newKey);
      }
    }
  }

  return keys;
}

export function transformI18n(message: any = '') {
  if (!message) {
    return '';
  }

  if (typeof message === 'object') {
    const locale: string | WritableComputedRef<string> | any = i18n.global.locale;
    return message[locale?.value];
  }

  const key = message.match(/(\S*)\./)?.input;

  if (key && getObjectKeys(getLocale('ru')).find((item) => item === key)) {
    return i18n.global.t.call(i18n.global.locale, message);
  } else if (!key && Object.keys(getLocale('ru')).includes(message)) {
    return i18n.global.t.call(i18n.global.locale, message);
  } else {
    return message;
  }
}

export function translateAPIError(error: RequestError | string): string {
  const defaultMsg = i18n.global.t('unexpected_error');

  let code = error;

  if (typeof error === 'object') {
    code = error?.response?.data?.errors?.[0]?.extensions?.code;
  }

  if (!error) return defaultMsg;
  if (!code === undefined) return defaultMsg;
  const key = `errors.${code}`;

  const exists = i18n.global.te(key);
  if (exists === false) return defaultMsg;
  return i18n.global.t(key);
}
