import api from './';
import { User, UserUpdatedData } from '@/types/users';
import { ErrorMessageResponse } from '~/types/error';

export const userApi = {
  sendVerifyCode(phone: string) {
    return api.post<boolean>('/auth/verify-code', { params: { phone } });
  },

  login(loginParams: { phone: string; verifyCode: string }) {
    return api.post<AuthenticationData | ErrorMessageResponse>('/auth/login', { data: { ...loginParams } });
  },

  logout() {
    return api.post<boolean>('/auth/logout');
  },

  me() {
    return api.get<User | ErrorMessageResponse>('/users/me');
  },

  getAvatar: async (userId: number | string) => {
    return api.get<string | ErrorMessageResponse>('/users/avatar', { params: { userId } });
  },

  updateAvatar: async (data: { avatar: File }) => {
    const formData = new FormData();
    formData.append('avatar', data.avatar);
    return api.post<void | ErrorMessageResponse>('/users/avatar', {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  },

  sendEmailConfirmationCode: async (email: string) => {
    return api.post<boolean | ErrorMessageResponse>(`/auth/confirm-email?email=${email}`);
  },

  checkEmailConfirmationCode: async ({ code, email }: { code: string | number; email: string }) => {
    return api.post<ErrorMessageResponse | boolean>(`/auth/verify-email`, { params: { code, email } });
  },

  signupWithEmail: async ({ password, email, username }: { password: string; email: string; username: string }) => {
    // const phone = faker.number.int();
    // const firstname = faker.person.firstName('male');
    // const lastname = faker.person.lastName('male');

    return api.post<ErrorMessageResponse | User>(`/auth/register-email`, {
      // data: { password, email, username, phone, tgId: 0, firstname, lastname },
      data: { password, email, username },
    });
  },

  sendTempPasswordToRegisteredEmail: async (email: string) => {
    return api.post<boolean | ErrorMessageResponse>(`/auth/send-temp-password`, { params: { email } });
  },

  checkTempPasswordForRegisteredEmail: async (password: string) => {
    return api.post<boolean | ErrorMessageResponse>(`/auth/verify-temp-password`, {
      params: { temporaryPassword: password },
    });
  },

  changeTempPasswordForRegisteredEmail: async ({ email, password }: { password: string; email: string }) => {
    return api.post<boolean | ErrorMessageResponse>(`/auth/change-password`, {
      params: { newPassword: password, email },
    });
  },

  loginWithPassword: async (data: { password: string; email: string }) => {
    return api.post<AuthenticationData | ErrorMessageResponse>(`/auth/login-email`, { data });
  },
  // update(id: number | string, data: UserUpdatedData) {
  // 	const formData = jsonToFormData(data);
  // 	return api.patch<User>(`/users/${id}`, { data: formData });
  // },
  // update(id: number | string, data: User) {
  // 	const formData = jsonToFormData(data);
  // 	return api.put<User>(`/users/${id}`, { data: formData });
  // },
  update(id: number | string, data: UserUpdatedData) {
    return api.put<User | ErrorMessageResponse>(`/users/?id=${id}`, { data });
  },

  getById(id: number | string) {
    return api.get<User>(`/users/?id=${id}`);
  },
  // getTelegramInfo(id: number) {
  //todo get telegram by tgId
  // return api.get(`/telegram/${id}`);
  // },
};

// function buildFormData<T>(formData: FormData, data: T, parentKey?: string) {
// 	if (
// 		data &&
// 		typeof data === 'object' &&
// 		!(data instanceof Date) &&
// 		!(data instanceof File) &&
// 		!(data instanceof Blob)
// 	) {
// 		Object.keys(data).forEach((key) => {
// 			buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
// 		});
// 	} else {
// 		const value = data == null ? '' : data;
//
// 		formData.append(parentKey, value);
// 	}
// }
//
// function jsonToFormData<T>(data: T) {
// 	const formData = new FormData();
//
// 	buildFormData(formData, data);
//
// 	return formData;
// }
