import { ErrorMessageResponse, ErrorResponse, ErrorResponseWithStatusCode } from '~/types/error';
import { User } from '~/types/users';
import { ErrorResponseWithExternalApiData } from '~/types/zoom';

export function isErrorMessageResponse(value: unknown): value is ErrorMessageResponse {
  return typeof value === 'object' && 'errorCode' in value;
}

export function isUser(value: unknown): value is User {
  return typeof value === 'object' && 'username' in value;
}

export function isAuthData(value: unknown): value is AuthenticationData {
  return typeof value === 'object' && 'username' in value && 'token' in value;
}

export function isErrorResponseWithExternalApiData(obj: unknown): obj is ErrorResponseWithExternalApiData {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'status' in obj &&
    typeof obj.status === 'number' &&
    'message' in obj &&
    typeof obj.message === 'string' &&
    'response' in obj &&
    typeof obj.response === 'object' &&
    obj.response !== null &&
    'message' in obj.response &&
    typeof obj.response.message === 'string' &&
    'error' in obj.response &&
    typeof obj.response.error === 'string' &&
    'statusCode' in obj.response &&
    typeof obj.response.statusCode === 'number'
  );
}

export function isErrorResponseWithStatusCode(value: unknown): value is ErrorResponseWithStatusCode {
  return typeof value === 'object' && 'statusCode' in value;
}

export function isErrorResponse(value: unknown): value is ErrorResponse {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const { response, status, message, name } = value as ErrorResponse;
  return (
    typeof response === 'object' &&
    response !== null &&
    typeof response?.message === 'string' &&
    typeof response?.error === 'string' &&
    typeof response?.statusCode === 'number' &&
    typeof status === 'number' &&
    typeof message === 'string' &&
    typeof name === 'string'
  );
}
