import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { ref } from 'vue';

/**
 * Global application state
 */
export const useAppStore = defineStore('appStore', () => {
  const sidebarCollapsed = useLocalStorage('app-store__sidebar-collapsed', window.innerWidth <= 1200, {
    shallow: true,
  });

  function toggleSidebarState() {
    sidebarCollapsed.value = !sidebarCollapsed.value;
  }

  /** Toggled visibility state notifications drawer */
  const notificationsDrawerOpened = ref(false);

  function toggleNotificationsDrawerState(open: boolean) {
    notificationsDrawerOpened.value = open;
  }

  const darkMode = useLocalStorage('app-store__darkTheme', window.matchMedia('(prefers-color-scheme: dark)').matches);

  function toggleDarkMode(dark?: boolean) {
    if (typeof dark === 'boolean') {
      darkMode.value = dark;
    }
    if (typeof dark !== 'boolean') {
      darkMode.value = !darkMode.value;
    }
  }

  /** Have all stores been hydrated. This indicates that the app is ready to be rendered */
  const hydrated = ref(false);

  /** Stores are currently being hydrated */
  const hydrating = ref(false);

  /** Global hydration error. App should not be rendered */
  const error = ref(null);

  /** Is the current user authenticated. @TODO maybe move to userStore */
  const authenticated = ref(false);

  /** How long until the access token will expire */
  const accessTokenExpiry = ref(0);

  return {
    accessTokenExpiry,
    toggleSidebarState,
    darkMode,
    toggleDarkMode,
    sidebarCollapsed,
    notificationsDrawerOpened,
    toggleNotificationsDrawerState,
    hydrated,
    hydrating,
    error,
    authenticated,
  };
});
