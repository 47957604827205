/* eslint-disable no-console */
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { version } from '../package.json';
import App from './app.vue';
import { registerComponents } from './components/register';
import { LOGO } from './constants';
import { registerDirectives } from './directives/register';
import { i18n } from './i18n';
import router from './router/';
import * as Sentry from '@sentry/vue';
import './styles/main.scss';
import { usePrimeVue } from './primevue';

init();

async function init() {
  console.log(LOGO);
  if (import.meta.env.DEV) {
    console.info(`%c🚀 Starting Neurohub v${version}...`, 'color:Green');
  } else {
    console.info('%c🚀 Starting Neurohub...', 'color:Green');
  }

  console.time('🕓 Application Loaded');

  const app = createApp(App);

  Sentry.init({
    app,
    dsn: 'https://477a994a9afa3c2f05ba9aad17be9d89@sentry.hugdev.ru/8',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.globalHandlersIntegration(),
      Sentry.linkedErrorsIntegration(),
      Sentry.dedupeIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.MODE,
  });

  app.use(i18n);
  app.use(createPinia());

  app.use(router);
  await router.isReady();

  app.use(usePrimeVue);

  registerDirectives(app);
  registerComponents(app);

  app.mount('#app');

  console.timeEnd('🕓 Application Loaded');

  console.group('%c📣 Project Information', 'color:DodgerBlue'); // groupCollapsed

  if (import.meta.env.DEV) {
    console.info(`%cVersion: v${version}`, 'color:DodgerBlue');
  }

  console.info(`%cEnvironment: ${import.meta.env.MODE}`, 'color:DodgerBlue');
  console.groupEnd();

  // Prevent the browser from opening files that are dragged on the window
  window.addEventListener('dragover', (e) => e.preventDefault(), false);
  window.addEventListener('drop', (e) => e.preventDefault(), false);
}
