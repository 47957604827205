import { App } from 'vue';
import TransitionBounce from './transition/bounce';
import TransitionDialog from './transition/dialog';
import TransitionExpand from './transition/expand';

export function registerComponents(app: App): void {
  app.component('TransitionBounce', TransitionBounce);
  app.component('TransitionDialog', TransitionDialog);
  app.component('TransitionExpand', TransitionExpand);
}
