import { dehydrate, hydrate } from './hydrate';
import { router } from './router';
import { useAppStore } from './stores/app';
import { RouteLocationRaw } from 'vue-router';
import { storageLocal } from '@pureadmin/utils';
import Cookies from 'js-cookie';
import { userApi } from '@/api/user';
import { isAuthData } from '~/types/guards/api-responses';
import { ErrorMessageResponse } from '~/types/error';
import { LoginParams } from '~/types/login';

export const UserKey = 'user-info';
export const TokenKey = 'authorized-token';

export async function sendVerifyCode(phone: string) {
  return userApi.sendVerifyCode(phone);
}

export async function login({
  strategy,
  phone,
  verifyCode,
  password,
  email,
}: LoginParams): Promise<AuthenticationData | ErrorMessageResponse> {
  const appStore = useAppStore();
  try {
    const authResponse =
      strategy === 'telegram'
        ? await userApi.login({ phone, verifyCode })
        : await userApi.loginWithPassword({ email, password });
    if (isAuthData(authResponse)) {
      setToken(authResponse);
      // Refresh the token 10 seconds before the access token expires. This means the user will stay
      // logged in without any noticeable hiccups or delays

      // setTimeout breaks with numbers bigger than 32bits. This ensures that we don't try refreshing
      // for tokens that last > 24 days. Ref #4054
      /* if (response.expires <= 2100000000) {
			 setTimeout(() => refreshToken(), response.expires - 10000);
		} */

      //todo fix this
      appStore.accessTokenExpiry = Date.now() + authResponse.expires;
      appStore.authenticated = true;
      await hydrate();
    }
    return authResponse;
  } catch (err) {
    return err;
  }
}

export async function refreshToken(_refreshToken: string): Promise<AuthenticationData> {
  // todo refresh token
  return null;
}

export enum LogoutReason {
  SIGN_OUT = 'SIGN_OUT',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}

export type LogoutOptions = {
  navigate?: boolean;
  reason?: LogoutReason;
};

/**
 * Everything that should happen when someone logs out, or is logged out through an external factor
 */
export async function logout(optionsRaw: LogoutOptions = {}): Promise<void> {
  const appStore = useAppStore();

  const defaultOptions: Required<LogoutOptions> = {
    navigate: true,
    reason: LogoutReason.SIGN_OUT,
  };

  removeToken();

  const options = { ...defaultOptions, ...optionsRaw };

  // Only if the user manually signed out should we kill the session by hitting the logout endpoint
  if (options.reason === LogoutReason.SIGN_OUT) {
    await userApi.logout();
  }

  appStore.authenticated = false;

  await dehydrate();

  if (options.navigate === true) {
    const location: RouteLocationRaw = {
      path: `/login`,
      query: { reason: options.reason },
    };

    await router.push(location);
  }
}

export function setToken(data: AuthenticationData) {
  let expires = 0;
  const { token, refreshToken } = data;
  expires = new Date(data.expires).getTime();
  const cookieString = JSON.stringify({ token, expires });

  expires > 0
    ? Cookies.set(TokenKey, cookieString, {
        expires: (expires - Date.now()) / 86400000,
      })
    : Cookies.set(TokenKey, cookieString);

  storageLocal().setItem(UserKey, data);
}

export function getToken(): AuthenticationData {
  return Cookies.get(TokenKey) ? JSON.parse(Cookies.get(TokenKey)) : storageLocal().getItem(UserKey);
}

export function removeToken() {
  Cookies.remove(TokenKey);
  storageLocal().removeItem(UserKey);
}

export const formatToken = (token: string): string => {
  return 'Bearer ' + token;
};
