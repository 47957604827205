import { RouteRecordRaw } from 'vue-router';
import { useCompanyStore } from '~/stores/company';

const Layout = () => import('@/layouts/index.vue');
const LoginLayout = () => import('@/layouts/empty-layout.vue');

export default [
  {
    path: '/login',
    component: LoginLayout,
    meta: {
      title: 'Login',
      showLink: false,
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/pages/login/index.vue'),
      },
      {
        path: 'phone',
        name: 'Phone account',
        component: () => import('~/pages/login/phone/enter-phone.vue'),
      },
      {
        path: 'phone/code/:phone',
        name: 'Enter code from sms',
        component: () => import('~/pages/login/phone/enter-code.vue'),
      },
      {
        path: 'telegram',
        name: 'Telegram account',
        component: () => import('~/pages/login/telegram/enter-phone.vue'),
      },
      {
        path: 'telegram/code/:phone',
        name: 'Enter code from telegram',
        component: () => import('~/pages/login/telegram/enter-code.vue'),
        props: true,
      },
      {
        path: 'unap',
        name: 'UNAP login',
        component: () => import('@/pages/login/unap/index.vue'),
      },
      {
        path: 'email',
        name: 'Email login',
        component: () => import('@/pages/login/email/index.vue'),
      },
      {
        path: 'email/recover',
        name: 'Recover by email',
        component: () => import('~/pages/login/email/recover/recover-start.vue'),
      },
      {
        path: 'email/signup',
        name: 'Signup with email',
        component: () => import('@/pages/login/email/signup/signup-start.vue'),
      },
      {
        path: 'email/signup/:email',
        name: 'Enter code from email to confirm',
        component: () => import('@/pages/login/email/signup/enter-code.vue'),
      },
      {
        path: 'email/signup/create-unap/:email',
        name: 'Create username and password',
        component: () => import('@/pages/login/email/signup/create-credentials.vue'),
      },
      {
        path: 'email/recover/temp-password/:email',
        name: 'Enter code from email to reset',
        component: () => import('@/pages/login/email/recover/enter-temp-password.vue'),
      },
      {
        path: 'email/recover/change-password/:email',
        name: 'Enter new password',
        component: () => import('@/pages/login/email/recover/change-password.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: 'home',
      rank: 0,
    },
    children: [
      {
        path: '',
        redirect: '/upload',
        name: 'Home',
      },
      {
        path: 'company/:id',
        name: 'Company',
        meta: {
          title: 'Company',
        },
        children: [
          {
            path: 'employees',
            name: 'Employees',
            component: () => import('@/pages/employees/index.vue'),
            meta: {
              title: 'Employees',
            },
          },
        ],
      },
      {
        path: 'upload',
        name: 'Upload',
        component: () => import('@/pages/upload/index.vue'),
        meta: {
          title: 'Upload',
        },
      },
      {
        path: 'profile/:id',
        name: 'Profile',
        component: () => import('@/pages/profile/index.vue'),
        meta: {
          title: 'Profile',
        },
      },
      {
        path: 'report/:type',
        name: 'Report',
        props: (route) => {
          const report = useCompanyStore().reports.find((report) => report.code === route.params.type);
          if (report) {
            return { url: report.url };
          }
          return { path: null };
        },
        component: () => import('@/pages/report/index.vue'),
        meta: {
          title: 'Report',
        },
      },
      {
        path: 'integrations',
        name: 'Integrations',
        component: () => import('@/pages/integrations/index.vue'),
        meta: {
          title: '3rd parties integrations',
        },
      },
      {
        path: 'no-company',
        name: 'NoCompany',
        component: () => import('@/pages/no-company/index.vue'),
        meta: {
          title: 'No Company',
        },
      },
    ],
  },
  {
    path: '/redirect',
    component: Layout,
    meta: {
      title: 'redirect',
      showLink: false,
      rank: 102,
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/layouts/redirect.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('~/pages/404/index.vue'),
  },
  // ] as Array<RouteConfigsTable>;
] as Array<RouteRecordRaw>;
